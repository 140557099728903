@import "./themes/generated/variables.base.scss";

.app {
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}

.side-nav-outer-toolbar .dx-drawer {
  height: calc(100% - 56px);
}

.app .content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.app .content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer
  > .dx-scrollable-wrapper
  > .dx-scrollable-container
  > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: white !important;
}

.dx-texteditor.dx-editor-filled.dx-state-disabled .dx-texteditor-input,
.dx-texteditor.dx-editor-filled.dx-state-readonly .dx-texteditor-input,
.dx-texteditor.dx-editor-filled.dx-state-readonly.dx-state-hover
  .dx-texteditor-input {
  color: white !important;
}

@media (min-width: 1280px) {
  .container {
    max-width: 100% !important;
  }
}

.footer-main {
  display: block;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  bottom: 0;
  border-top: 1px solid #757575;
  width: 100%;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row > tr > td:not(.dx-focused) {
  background-color: rgba(3, 169, 244, 0.07);
  color: #fff;
}

.dx-datagrid-group-opened,
.dx-datagrid-group-closed {
  color: rgba(255, 255, 255, 0.54) !important;
}
